import apiInstance from './index'
import queryParams from './queryParams'

export default {
  async getUsers(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`users${query}`)
  },

  async setUser(user) {
    return await apiInstance.post('users', user)
  },

  async getUser(id, paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`users/${id}${query}`)
  },

  async updateUser(id, data) {
    return await apiInstance.put(`users/modify/${id}`, data)
  },

  async deleteUser(id) {
    return await apiInstance.delete(`users/${id}`)
  },

  async statusUser(id) {
    return await apiInstance.put(`users/status/${id}`)
  },

  async setBlockStatus(id, data) {
    return await apiInstance.post(`users/${id}/blocked-status`, data)
  },

  async updateUserInfo(id, data) {
    return await apiInstance.put(`users/${id}`, data)
  },

  async getUserInfo(id) {
    return await apiInstance.get(`users/${id}/edit`)
  },

  async setUserLocation(data) {
    return await apiInstance.post('user/location', data)
  },

  async getUserAuthenticated(data) {
    return await apiInstance.get('user/authenticated', { params: data })
  },

  async setNewPassword(data) {
    return await apiInstance.post('users/change-password', data)
  },

  async resetPassword(data) {
    return await apiInstance.post('users/reset-password', data)
  },

  async getAdministratorUsers() {
    return await apiInstance.get('users/type/administrators')
  },
}
